<template>
  <v-col cols="12" class="py-0">
      <v-flex xs12 sm12 md12 lg12 xl12>
        <v-data-table
            height="50vh"
            :items="items"
            :items-per-page="-1"
            :headers="getHeader(headers) || []"
            @dblclick:row="setModalData"
            fixed-header disable-sort
            single-select calculate-widths
        >
          <template v-slot:top>
            <v-col cols="12" class="d-flex pa-0">
              <span class="text-h6">목록(총 {{ items.length }}개)</span>
              <v-btn class="ml-auto primary mb-1" @click="showModal(false)">추가</v-btn>
            </v-col>
          </template>

          <template v-slot:item.mobileUrl="{ value }">
            <span>{{ getUrl(value) }}</span>
          </template>
          <template v-slot:item.pcUrl="{ value }">
            <span>{{ getUrl(value) }}</span>
          </template>

          <template v-slot:item.partner="{ value }">
            <span>{{ value === "Y" ? "제휴" : "미제휴" }}</span>
          </template>
          <template v-slot:item.insProductType="{ value }">
            <span>{{ insProductType(value) }}</span>
          </template>
          <template v-slot:item.type="{ value }">
            <span>{{ getInsurerType(value) }}</span>
          </template>

          <template v-for="(info, index) in filed_YN" v-slot:[info]="{ value }">
            <span :key="index">{{ value === "Y" ? "노출" : "미노출" }}</span>
          </template>



        </v-data-table>
      </v-flex>
    <insurer-modal
      :is-show="isShow"
      :is-modify="isModify"
      :insurer-data="modalData"
      @close="isShow = false"
      @afterModify="afterModify"
    />
  </v-col>
</template>

<script>
import _ from 'lodash';
import InsurerModal from "@/pages/User/components/InsurerSearch/InsurerModal";
import StringMixin from "@/mixin/StringMixin";
import TableMixin from "@/mixin/TableMixin";

export default {
  mixins: [StringMixin, TableMixin],
  components: {InsurerModal},
  props: {
    items: Array,
    headers: Array,
  },
  data: () => ({
    isShow: false,
    isModify: false,
    filed_YN: ["item.internet", "item.telephone", "item.view"],
    modalData: {},
  }),
  methods: {
    getInsurerType(type) {
      if(type === 'CAR') return "자동차";
      if(type === 'TWO-WHEELER') return "이륜차";
      if(type === 'DRIVER') return "운전자";
      if(type === 'TRAVELER') return "여행자";
    },
    insProductType(value){
      if (value === "I") return '개인';
      if (value === "B") return '법인';
      if (value === "C") return '영업';
    },
    close() {
      this.modifyData = {};
      this.isModifyShow = false;
    },
    showModal(isModify) {
      if(!isModify) this.modalData = {};
      this.isModify = isModify;
      this.isShow = true;
    },
    setModalData(evt, { item }) {
      this.modalData = _.cloneDeep(item);
      this.showModal(true);
    },
    afterModify() {
      this.$emit('afterModify');
    },
  }
}
</script>

<style scoped>

</style>