<template>
  <search-page-layout title="정산내역 상세">

    <template v-slot:searchBar>
      <settlement-detail-search-bar
          @getSettlementDetail="getSettlementDetail"
          @setCheckMappCdChange="setCheckMappCdChange"
          :isPrvGroupTM="isPrvGroupTM"
      />
    </template>

    <template v-slot:table>
      <settlement-detail-table
          :data="data"
          :loading="loading"
          @toExcel="toExcel"
          @update="update"
          :isPrvGroupTM="isPrvGroupTM"
      />
    </template>
  </search-page-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import SettlementDetailSearchBar from "@/pages/Settlement/components/SettlementDetail/SettlementDetailSearchBar";
import SettlementDetailTable from "@/pages/Settlement/components/SettlementDetail/SettlementDetailTable";
import _ from "lodash";
import func from "@/util/func";

export default {
  props: ["name"],
  components: {
    SettlementDetailTable,
    SettlementDetailSearchBar,
    SearchPageLayout,
  },
  data: () => ({
    loading: false,
    data: [],
    dataBack: [],
    conditionHist: [],
    searchCheckMap: "ALL",
  }),
  computed: {
    isPrvGroupTM() {
      return func.checkUserPrvGroup(["TM", "TM_ADM"]) === "Y";
    }
  },
  methods: {
    async getSettlementDetail(params) {
      this.loading = true;
      this.conditionHist = params;

      try {
        const {data} = await axiosInstance.get('/admin/settlement/detail', { params });

        for (let i = 0; i < data.length; i++) {
          //점검확인(Y: 확인 N:미확인 null: 미표시) 표시
          if(data[i].checkYn === "Y"){
            data[i].checkResultYn = "확인"
          }else if(data[i].checkYn === "N"){
            data[i].checkResultYn = "미확인"
          }
          //검수대상 조회 조건(전체자료, 검수대상건) 데이터 표출
          if(params.checkRage == "SEL" && data[i].checkResult === "정상"){
            data.splice(i, 1);
          }
        }
        this.data = data;
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].index = i;
        }
        this.dataBack = data;
      } catch (e) {
        console.log(e);
      }

      this.setCheckMappCdChange(this.searchCheckMap);
      this.loading = false;
    },
    async toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "서비스구분": item["serviceType"],
          "조기정산ID": item["earlySettlementHistId"],
          "가입자명": item["insuredNm"],
          "가입자 전화번호": item["insuredTelNum"],
          "가입자 차량번호": item["insuredCarNo"],
          "리워드타입": item["rewardType"],
          "회원명": item["recipientNm"],
          "회원 전화번호": item["recipientTelNum"],
          "고객명": item["customerNm"],
          "고객 전화번호": item["customerTelNum"],
          "고객 차량번호": item["customerCarNo"],
          "상태": item["rewardStatus"],
          "점검결과": item["checkResult"],
          "점검확인": item["checkResultYn"],
          "보험사": item["contractInsurer"],
          "계약채널": item["joinChannel"],
          "계약보혐료": item["contractPremAmt"],
          "리워드": item["rewardAmt"],
          "계약일": item["contractDate"],
          "만기일": item["expiryDate"],
          "지급예정일": item["payDueDate"],
          "지급완료일": item["payCompleteDate"],
          "비고": item["remark"],
          "설계지원여부": item["designSupYn"],
          "설계지원번호": item["designSupId"],
          "상담사": item["csNm"],
          "진행자": item["ctCsNm"],
          "이륜차여부": item["twoWheelerYn"],
          "법인계약여부": item["corpContYn"]
        }
        returnData.push(obj);
      }

      const sheetName = `정산내역상세`;
      const fileName = `정산내역상세`;
      let conditionHistString = JSON.stringify(this.conditionHist);

      await func.saveXlsExtractHist("정산내역 상세", returnData.length, conditionHistString, fileName, "HIGH");
      func.extractExcelFile(returnData, sheetName, fileName);
    },
    update() {
      this.getSettlementDetail(this.conditionHist)
    },
    setCheckMappCdChange(searchCheckMap) {
      this.searchCheckMap = searchCheckMap;

      if("ALL" == searchCheckMap)
      {
        this.data = this.dataBack;
      }
      else if("정상" == searchCheckMap)
      {
        this.data = this.dataBack.filter(value => value.checkResult === "정상");
      }
      else if("오류" == searchCheckMap)
      {
        this.data = this.dataBack.filter(value => value.checkResult !== "정상");
      }
    },
  }
}
</script>