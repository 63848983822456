<template>
	<search-bar-layout>
	<template v-slot:contents>
		
		<search-area>
      <field-input title="생성날짜">
        <date-input
            :start-date="searchFileStartDate"
            :end-date="searchFileEndDate"
            @updateDate="updateDate"
        />
      </field-input>
			<field-input title="태그">
        <v-combobox
            v-model="selectTags"
            :items="searchFileTagList"
            item-text="searchFileTagNm"
            item-value="searchFileTag"
            placeholder="전체"
            outlined hide-details dense
            background-color="white">
        </v-combobox>
			</field-input>
			<field-input title="파일이름">
				<v-text-field
					v-model="searchFileName"
					outlined dense hide-details
					background-color="white" menu-props="auto"
					@keypress.enter="getContents"
				/>
			</field-input>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn class="mr-2"  @click="getContents">
          조회
        </v-btn>
        <v-btn class="mr-2" @click="$emit('regist')">
          등록
        </v-btn>
        <v-btn class="red lighten-2" @click="delContents">
          삭제
        </v-btn>
      </v-col>
		</search-area>
	</template>
	</search-bar-layout>
</template>

<script>

import SearchBarMixin from "@/mixin/SearchBarMixin";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import FieldInput from "@/components/Form/FieldInput";
import CodeMixin from "@/mixin/CodeMixin";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";

export default {
	mixins: [CodeMixin, SearchBarMixin,DateMixin],

	components: {FieldInput, SearchArea, SearchBarLayout, DateInput},
  props: ["defaultDates", "searchFileTagList"],
	beforeMount() {
    // 시작 날짜, 종료 날짜 초기 설정
    const date = new Date();
    const [syear, smonth] = [date.getFullYear(), date.getMonth() - 1];
    const [eyear, emonth, edate] = [date.getFullYear(), date.getMonth(), date.getDate()];
    this.searchFileStartDate = `${syear}-${this.addZero(smonth + 1)}-01`;
    this.searchFileEndDate = `${eyear}-${this.addZero(emonth + 1)}-${this.addZero(edate)}`;
    this.$emit("defaultDate", [this.searchFileStartDate, this.searchFileEndDate]);
  },
	data() {
    return {
      selectFile:null,
      registTag: "",
      registData: {
        fileType:"",
        fileName:"",
        fileSize:"",
        fileString:null,
        width: 0,
        height: 0
      },
      selectTags: { searchFileTagNm: "전체", searchFileTag: "" },
      searchFileName: "",
      searchFileStartDate: "",
      searchFileEndDate: ""
    }
  },
  methods: {
		updateDate(values) {
      this.searchFileStartDate = values[0];
      this.searchFileEndDate = values[1];
    },
		getContents() {
			let params = {}

			if(this.selectTags !== "")  params["tag"] = this.selectTags.searchFileTag
			if(this.searchFileName !== "")  params["fileNm"] = this.searchFileName

			if(this.searchFileStartDate !== "" && this.searchFileEndDate !== "") {
        params["startDate"] = this.searchFileStartDate
        params["endDate"] = this.searchFileEndDate
			}

			this.$emit('search', params);
		},
		delContents() {
			this.$emit('delContents')
		}
	},



}

</script>