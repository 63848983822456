<template>
	<search-page-layout >
	<template v-slot:searchBar>
		
		<!--@search="getContentsAPI" : 조회 버튼 클릭시, 자식 컴포넌트에서 받은 param으로 이미지 및 파일 리스트 조회 API -->
		<!--@delContents="delContentsAPI" : 삭제 API -->
		<contents-file-search-bar
      :searchFileTagList="searchFileTagList"
      @defaultDate="getDefaultDate"
			@search="getContentsAPI" 
			@delContents="delContentsAPI"
			@regist="dialog = true"
		/>
	</template>
	<template v-slot:table>
		<!-- @deletelist="setDeleteList" {seq : value, check : value } 형태로, 체크박스 대상에 대한 true false를 자식 컴포넌트로 부터 업데이트 받고 -->
		<contents-file 
			@deletelist="setDeleteList"
			:contents="contentsList"
		/>
	<regist-file-dialog
		:is-show="dialog"
    :searchFileTagList="searchFileTagList"
		@close="dialog = false"
		@refresh="refreshContentsAPI"
    @regist="refreshTag"
	/>
	</template>
	
	</search-page-layout>
</template>

<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import {axiosInstance} from "@/util/AxiosModule";
import ContentsFile from "@/pages/Manage/ContentsFile/components/ContentsFile"
import ContentsFileSearchBar from "@/pages/Manage/ContentsFile/components/ContentsFileSearchBar"
import RegistFileDialog from "@/pages/Manage/ContentsFile/components/RegistFileDialog"
export default {
	components: {SearchPageLayout,ContentsFile, ContentsFileSearchBar, RegistFileDialog},
	data: ()=> ({
		dialog:false,
		deleteList: [],
    defaultDates: [],
    searchFileTagList: [],
		contentsList : []
  }),
  created() {
    this.getContFileInfoTags();
  },
  mounted() {
		this.refreshContentsAPI();
	},
	methods: {
    getDefaultDate(dates) {
      this.defaultDates = dates;
    },
    async getContFileInfoTags() {
      this.searchFileTagList = [];
      try {
        const { data } = await axiosInstance.get("/admin/operation/getContFileInfoTags");
        data.map(item => {
          this.searchFileTagList.push({
            searchFileTagNm: item === "" ? "전체" : item,
            searchFileTag: item
          })
        });
      } catch (error) {
        console.log(error);
      }
    },
		async getContentsAPI(params) {
			// 조건 조회 API
			console.log("getContentsAPI")
			try{
				const {data} = await axiosInstance.get("/admin/operation/contFileInfo", {params});
				this.contentsList = this.contentsDataFormatter(data);
			}
			catch(e) {
				console.log(e)
				alert("컨텐츠 GET 실패했습니다.")
			}

		},
		async refreshContentsAPI() {
      const params = {
        startDate: this.defaultDates[0],
        endDate: this.defaultDates[1]
      }
			// 갱신 API
			try{
				const {data} = await axiosInstance.get("/admin/operation/contFileInfo", { params });
				this.contentsList = this.contentsDataFormatter(data);
				this.deleteList = []
			}
			catch(e) {
				console.log(e)
				alert("컨텐츠 갱신에 실패했습니다.")
			}
		},
		async delContentsAPI() {
			// 삭제 API
			var queryParams = this.deleteList.join(",")
			this.deleteList.join(",")
			
			const params = {seq: queryParams}
			if (confirm("데이터를 삭제하시겠습니까?")) {
        try {
          await axiosInstance.delete("/admin/operation/contFileInfo", {params});
          await this.refreshContentsAPI();
          this.refreshTag();
        }
        catch(e) {
          console.log(e)
          alert("컨텐츠 지우는데 실패했습니다.")
        }
      }
			
		},
		setDeleteList(params) {
			// true : 체크박스 활성 ==> 삭제할 아이템 선택
			// false : 체크박스 비활성 ==> 삭제 대상 아님
			if(params.check)
				this.deleteList.push(params.seq)
			else {
				// 만약, this.deleteList에 해당 seq가 있으면, 빼는 작업
				this.deleteList = this.deleteList.filter((element) => element !== params.seq);
			}
		},
		contentsDataFormatter(contents) {
			
			// 시간 yyyy-mm-dd로 맞춰줌
			for ( var data in contents) {
				//ex) 2021-11-02T17:47:13.677395
				let d = contents[data].createDate ? contents[data].createDate : '';
				contents[data].createDate =  d ? `${d.slice(0, 4)}-${d.slice(5, 7)}-${d.slice(8,10)} ${d.slice(11,19)}` : ''; // 2021-11-02 17:47:13
			}
			return contents
		},
    refreshTag() {
      this.getContFileInfoTags();
    }
	}

}
</script>
